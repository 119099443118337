import React, { useEffect, useState, useContext } from 'react';
import shuffle from 'lodash/shuffle';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import Avatar from 'seedly-component-library/avatar-new';
import Button from 'seedly-component-library/button-new';
import Link from 'components/misc/Link';
import formatCount from 'utils/formatCount';
import { useSelectGroupList } from 'entity/group/groupHooks';
import groupApi from 'entity/group/groupApi';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { ArticlePageContext } from 'pageContents/BlogContent/context/ArticlePageContext';

const Groups = ({ group }) => {
  if (isEmpty(group)) return null;

  return (
    <div className="flex gap-2 items-center" key={group.id}>
      <Avatar sizeClass="avatar-10" src={group.coverImage} />
      <div className="flex flex-col w-[140px]">
        <p className="text-sm font-bold">{group.name}</p>
        <p className="text-xs text-neutral-500">
          <span role="img" aria-label="emoji">
            🤓
          </span>{' '}
          {formatCount(group.membersCount)} members
        </p>
        <p className="text-xs text-neutral-500">{group.description}</p>
      </div>
      <Link
        href={`/community/${group.slug}`}
        as={`/community/${group.slug}`}
        passHref
      >
        <a>
          <Button
            onClick={() => {
              mixpanel.track(trackedEvents.ArticleSidebarClicked, {
                params: 'join_our_community',
                slug: group.slug,
              });
            }}
          >
            Explore
          </Button>
        </a>
      </Link>
    </div>
  );
};

const JoinCommunity = () => {
  const allGroups = shuffle(useSelectGroupList());
  const [relatedGroup, setRelatedGroup] = useState({});
  const { article } = useContext(ArticlePageContext);

  const router = useRouter();
  const { categorySlug } = router.query;
  const currentBlogArticleCategory = article && article.categories[0];

  useEffect(() => {
    async function getGroupSlug(slug) {
      const response = await groupApi.getMappedTopicToGroup(slug);
      if (response.ok) {
        const groupSlug = response.data.data.slug;
        const groupFromSlug = allGroups.filter(
          group => group.slug === groupSlug,
        );
        setRelatedGroup(groupFromSlug[0]);
      } else {
        setRelatedGroup(allGroups[2]);
      }
    }

    if (currentBlogArticleCategory?.slug || categorySlug) {
      getGroupSlug(currentBlogArticleCategory?.slug || categorySlug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <div className="card flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <h3 className="text-2xl font-bold">
          Join our{' '}
          <span className="text-success text-2xl font-bold">Community!</span>
        </h3>
        <p className="text-neutral-500">
          Discuss your thoughts with like-minded members in these community
          groups!
        </p>
      </div>
      <div className="horizontal-divider" />
      <div className="flex flex-col gap-4 items-center">
        {isEmpty(relatedGroup) ? (
          allGroups.slice(0, 3).map(group => {
            return <Groups key={group.slug} group={group} />;
          })
        ) : (
          <>
            <Groups group={relatedGroup} />
            {allGroups.slice(0, 2).map(group => {
              return <Groups key={group.slug} group={group} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default JoinCommunity;
