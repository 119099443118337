import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getFeaturedCommentsSuccess } from 'entity/comment/commentRedux';
import { ArticleWithLink } from '../blogTypes';

const useInsertArticleComment = () => {
  const dispatch = useDispatch();

  return useCallback(
    (articles: ArticleWithLink[]) => {
      const articleComments = articles
        .filter(article => !!article.featuredComment)
        .map(article => {
          return {
            pagination: {
              currentPage: 0, // trivia because initial
              per: 1,
              totalPage: article.commentsCount,
              totalCount: article.commentsCount,
            },
            commentableId: article.id,
            comments: [article.featuredComment],
          };
        });

      dispatch(
        getFeaturedCommentsSuccess({
          commentsByCommentable: articleComments,
          commentableType: 'Article',
        }),
      );
    },
    [dispatch],
  );
};

export default useInsertArticleComment;
