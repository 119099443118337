import { blogImage } from 'theme';
import { Colors } from 'app/seedly-component-library';

export const pinnedArticles = [
  {
    slug:
      'read-me-first-your-personal-finance-journey-starts-with-this-article',
    image: blogImage.personalFinance,
    title: 'Personal\nFinance 101',
    backgroundColor: Colors.green5,
    views: 81000,
  },
  {
    slug: 'ultimate-personal-finance-guide-to-investing-singapore',
    image: blogImage.investment,
    title: 'Investment\n101',
    backgroundColor: Colors.teal1,
    views: 100000,
  },
  {
    slug:
      'ultimate-personal-finance-compilation-of-articles-for-fresh-graduates-first-job',
    image: blogImage.freshGrad,
    title: 'Fresh Grad\n(24 - 30)',
    backgroundColor: Colors.seedlyBlue,
    views: 62000,
  },
  {
    slug: 'personal-finance-compilation-adulthood',
    image: blogImage.nsf,
    title: 'Adulthood\n(30 - 40)',
    backgroundColor: Colors.purple1,
    views: 26000,
  },
];
