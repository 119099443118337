import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { blogImage } from 'theme';
import { usePostList } from 'entity/post/postHooks';
import { getFeedsRequest } from 'entity/feed/feedRedux';

const useTrendingCommunityPost = () => {
  const dispatch = useDispatch();
  const trendingPosts = usePostList();

  useEffect(() => {
    dispatch(getFeedsRequest({ feedFilter: 'trending' }));
  }, [dispatch]);

  const trendingPost = trendingPosts.map(post => {
    const randomImage = `${
      blogImage.postPlaceHolder
    }/trending_post_placeholder_${Math.floor(Math.random() * 5) + 1}.jpg`;

    const author = {
      imageUrl: post.group.profileImage,
      link: {
        as: `/group/${post.group.slug}`,
        href: `/group/${post.group.slug}`,
      },
      name: post.group.name,
      slug: post.group.slug,
      description: post.group.description,
      id: `${post.group.id}`,
    };

    return {
      title: post.title,
      author,
      publishDate: post.createdAt,
      link: {
        as: `/posts/${post.slug}`,
        href: `/posts/${post.slug}`,
      },
      imageUrl: post.picture || randomImage,
      answersCount: post.answersCount,
      slug: post.slug,
    };
  });

  if (trendingPosts.length === 0) return [];

  return trendingPost;
};

export default useTrendingCommunityPost;
