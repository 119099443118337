import React from 'react';
import AdSlot from 'app/components/misc/AdSlot';
import clsx from 'clsx';

import SidebarSection from '../SidebarSection';

const BlogLayout = props => {
  const { showGoogleAds, stickySidebarDisabled, children } = props;

  return (
    <div className="lg:mt-16 m-auto flex flex-col w-full items-center">
      {showGoogleAds && <AdSlot type="blog" slotKey="top-leaderboard" />}
      <div className="flex flex-row gap-6 w-full lg:w-fit">
        <div
          className={clsx(
            'w-full lg:w-[756px]',
            showGoogleAds ? 'mt-0' : 'mt-4',
          )}
        >
          {children}
        </div>
        <div
          className={clsx(
            'w-[310px] desktop-only',
            showGoogleAds ? 'mt-0 mb-[158px]' : 'mt-4 mb-0',
            stickySidebarDisabled ? 'static' : 'sticky',
          )}
        >
          <SidebarSection />
        </div>
      </div>
      {showGoogleAds && <AdSlot type="blog" slotKey="bottom-leaderboard" />}
    </div>
  );
};

export default BlogLayout;
