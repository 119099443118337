import React from 'react';
import ContentLoader from 'react-content-loader';
import { Colors } from 'seedly-component-library';
import styled from 'styled-components';
import fill from 'lodash/fill';

const HEIGHT = 90;
const WIDTH = 322;

const Container = styled.div`
  height: ${HEIGHT}px;
  svg {
    height: ${HEIGHT}px;
  }
`;

const List = styled.ul`
  background-color: white;
`;

const ListItem = styled.li`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 16px;
  }
`;

const Loader = () => {
  return (
    <Container>
      <ContentLoader
        height={HEIGHT}
        width={WIDTH}
        ariaLabel="Loading articles"
        primaryColor={Colors.neutral2}
        secondaryColor={Colors.neutral4}
      >
        <rect x="0" y="0" rx="2" ry="2" width="322" height="48" />
        <rect x="0" y="60" rx="2" ry="2" width="322" height="16" />
      </ContentLoader>
    </Container>
  );
};

const PopularArticleLoader = ({ itemCount }) => {
  const data = fill(Array(itemCount), null);

  return (
    <>
      {data.map((_, index) => {
        return (
          <List key={index}>
            <ListItem>
              <Loader />
            </ListItem>
          </List>
        );
      })}
    </>
  );
};

export default PopularArticleLoader;
