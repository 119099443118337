import React from 'react';
import { useRouter } from 'next/router';

import { PathContext } from 'app/context';
import SearchProvider from 'app/components/search/SearchProvider';
import BasePage from './BasePage';

const BlogBasePage = ({ children, ...rest }) => {
  const router = useRouter();

  return (
    <PathContext.Provider value={{ path: router.pathname, host: 'blog' }}>
      <SearchProvider>
        <BasePage {...rest}>{children}</BasePage>
      </SearchProvider>
    </PathContext.Provider>
  );
};

export default BlogBasePage;
