import React from 'react';
import truncate from 'lodash/truncate';
import dayjs from 'dayjs';
import Avatar from 'seedly-component-library/avatar-new';
import Link from 'components/misc/Link';
import clsx from 'clsx';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { PopularArticleItemProps } from './PopularArticleItem';

const MobilePopularArticleItem = (props: PopularArticleItemProps) => {
  const {
    title,
    author,
    publishDate,
    link,
    imageUrl,
    itemType = 'blog',
    answersCount = 0,
    slug,
  } = props;

  return (
    <Link {...link} passHref>
      <a
        onClick={() => {
          mixpanel.track(trackedEvents.MobileArticleCarousel, {
            params:
              itemType === 'blog'
                ? 'whats_popular_blog'
                : 'whats_popuar_community',
            slug,
          });
        }}
      >
        <div className="card relative p-2 pt-6 w-[200px] h-[240px] border border-neutral-400 rounded-lg">
          <span
            className={clsx(
              'absolute -top-2.5 left-2 rounded-lg p-2 pt-1 pb-1 text-white text-xs font-bold uppercase',
              itemType === 'blog' ? 'bg-blue-500' : 'bg-green-500',
            )}
          >
            {itemType === 'blog' ? 'BLOG' : 'COMMUNITY'}
          </span>
          <img
            src={imageUrl}
            alt={title}
            height={105}
            width={200}
            className="mb-1 w-[200px] h-[105px] rounded-lg"
          />
          <div className="flex flex-col h-[96px] justify-between w-full">
            <p className="text-sm font-bold">
              {truncate(title, { length: 70 })}
            </p>
            <div className="flex justify-between w-full">
              <div className="flex flex-row gap-1 items-center">
                <Avatar sizeClass="avatar-4" src={author.imageUrl} />
                <p className="text-xs text-neutral-500">
                  {truncate(author.name, {
                    length: itemType === 'blog' ? 10 : 20,
                  })}
                </p>
              </div>
              <p className="text-xs text-neutral-500">
                {itemType === 'blog' ? (
                  dayjs(publishDate).format('MMM DD, YYYY')
                ) : (
                  <>
                    <span role="img" aria-label="speech" className="mr-1">
                      💬
                    </span>
                    {`${answersCount}`}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default MobilePopularArticleItem;
