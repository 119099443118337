import React from 'react';
import styled from 'styled-components';
import { styles } from 'theme';
import he from 'he';
import Link from 'app/components/misc/Link';

export const Container = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: ${styles.headerHeight};
  min-width: 182px;
  max-height: 320px;
  overflow-y: scroll;
`;

const List = styled.ul`
  background-color: #ffffff;
  border-radius: ${props => props.theme.borderRadius.md};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  padding: 5px 0;
`;

const ListItem = styled.li`
  color: ${props => props.theme.colors.neutral5};

  &:hover {
    color: ${props => props.theme.colors.seedlyBlue};
  }
`;

const Anchor = styled.a`
  display: block;
  font-weight: bold;
  padding: 10px 14px 10px;
  transition: none;
`;

const SubMenu = ({ subCategories }) => {
  const sortedSubCategories = subCategories.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  return (
    <Container className="sub-menu">
      <List>
        {sortedSubCategories.map(category => {
          return (
            <ListItem key={category.id}>
              <Link href={category.link.href} as={category.link.as} passHref>
                <Anchor>{he.decode(category.title)}</Anchor>
              </Link>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default SubMenu;
