import React from 'react';
import styled from 'styled-components';
import he from 'he';
import { Colors, Text } from 'app/seedly-component-library';
import { ChevronDown } from 'react-feather';
import isEmpty from 'lodash/isEmpty';
import Link from 'app/components/misc/Link';
import { styles } from 'theme';
import { DesktopOnly } from 'app/components/misc/Layout';
import blogMenu from 'app/constants/blogMenu';
import SubMenu from './SubMenu';

const List = styled.ul`
  display: flex;
  margin: auto;
  width: min-content;
  height: ${styles.headerHeight};
`;

const ListItem = styled.li`
  position: relative;
  padding: 0 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  &:first-child {
    padding-left: 0;
    cursor: default;
  }

  &:nth-child(2) {
    cursor: default;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    > a > p {
      font-weight: bold;
      color: ${props => props.theme.colors.seedlyBlue};
    }

    > a > svg {
      stroke: ${props => props.theme.colors.seedlyBlue};
    }

    & .sub-menu {
      display: block;
    }
  }
`;

const Anchor = styled.a`
  display: block;
  align-items: center;
  display: flex;
`;

const Title = styled(Text)`
  width: max-content;

  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

const BlogSubHeader = () => {
  return (
    <DesktopOnly>
      <List>
        <ListItem>
          <p className="text-sm font-bold" variant="titlemd">
            Blog
          </p>
        </ListItem>
        <ListItem>
          <div className="vertical-divider h-6" />
        </ListItem>
        {blogMenu.map(category => {
          const categoryTitle = he.decode(category.title);
          const withSubcategories = !isEmpty(category.subcategories);
          return (
            <ListItem key={category.id}>
              <Link href={category.link.href} as={category.link.as} passHref>
                <Anchor>
                  <Title intent="secondary" title={categoryTitle}>
                    {categoryTitle}
                  </Title>
                  {withSubcategories && (
                    <ChevronDown size={16} color={Colors.neutral5} />
                  )}
                </Anchor>
              </Link>
              {withSubcategories && (
                <SubMenu subCategories={category.subcategories} />
              )}
            </ListItem>
          );
        })}
      </List>
    </DesktopOnly>
  );
};

export default BlogSubHeader;
