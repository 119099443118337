import { RootState } from 'app/reducer';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const getIds = (state: RootState) => state.post.ids;
const getById = (state: RootState) => state.post.byId;

const postsSelector = createSelector([getIds, getById], (ids, byId) => {
  return ids.map(id => byId[id]);
});

const useHasMorePost = () => {
  return useSelector((state: RootState) => {
    const pagination = state.post.pagination;
    if (!pagination) return false;

    const { totalPage, currentPage } = pagination;

    if (totalPage === 0 || totalPage === currentPage) return false;

    return true;
  });
};

const usePostTotalCount = () => {
  return useSelector((state: RootState) => {
    const pagination = state.post.pagination;
    if (!pagination) return 0;
    return pagination.totalCount;
  });
};

const usePostCurrentPage = () => {
  return useSelector((state: RootState) => {
    const pagination = state.post.pagination;
    if (!pagination) return 0;
    return pagination.currentPage;
  });
};

const usePostApiStatus = () => {
  return useSelector((state: RootState) => {
    const apiStatus = state.post.api;
    return apiStatus;
  });
};

const useGetPinnedPost = () => {
  return useSelector((state: RootState) => {
    const pinnedId = state.post.ids.find(id => {
      return state.post.byId[id].campaignPostState === 'live';
    });
    if (pinnedId) return state.post.byId[pinnedId];
    return null;
  });
};

const usePostList = () => {
  return useSelector(postsSelector);
};

const useGetPostById = () => {
  return useSelector(getById);
};

export {
  useGetPinnedPost,
  useGetPostById,
  useHasMorePost,
  usePostCurrentPage,
  usePostApiStatus,
  usePostList,
  usePostTotalCount,
};
