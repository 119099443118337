import React from 'react';
import truncate from 'lodash/truncate';
import Avatar from 'seedly-component-library/avatar-new';
import Link from 'components/misc/Link';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { formatDateString } from 'utils/convertDateFormat';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

interface LinkObj {
  as: string;
  href: string;
}

interface AuthorProps {
  description: string;
  id: number | string;
  imageUrl: string;
  link: LinkObj;
  name: string;
  slug: string;
}

export interface PopularArticleItemProps {
  title: string;
  author: AuthorProps;
  publishDate: string;
  duration?: string;
  imageUrl: string;
  link: LinkObj;
  itemType: 'blog' | 'community';
  answersCount?: number;
  slug: string;
}

const SectionTitle = ({ title }: Partial<PopularArticleItemProps>) => {
  const truncatedTitle = truncate(title, { length: 100 });

  return <a className="text-sm font-bold">{truncatedTitle}</a>;
};

const SectionPublisher = ({ author }: Partial<PopularArticleItemProps>) => {
  return (
    <div className="flex gap-1 items-center">
      <Avatar src={author.imageUrl} sizeClass="avatar-4" />
      <p className="text-xs text-neutral-500">{author.name}</p>
    </div>
  );
};

const SectionDate = ({
  publishDate,
  itemType,
  answersCount,
}: Partial<PopularArticleItemProps>) => {
  const displayDate =
    itemType === 'blog'
      ? dayjs(publishDate).format('MMM DD, YYYY')
      : `Posted ${formatDateString(publishDate)}`;

  return (
    <div className="flex gap-1 items-center">
      <p className="text-xs text-neutral-500">
        <time dateTime={new Date(publishDate).toISOString()}>
          {displayDate}
        </time>
      </p>
      <p className="text-xs text-neutral-500">∙</p>
      <p className="text-xs text-neutral-500">
        <span role="img" aria-label="speech">
          💬
        </span>{' '}
        {`${answersCount}`}
      </p>
    </div>
  );
};

const PopularArticleItem = (props: PopularArticleItemProps) => {
  const {
    title,
    author,
    publishDate,
    link,
    imageUrl,
    itemType = 'blog',
    answersCount = 0,
    slug,
  } = props;

  return (
    // backslash added to avoid redirect
    <Link href={`${link.href}/`} as={`${link.as}/`} passHref>
      <a
        onClick={() => {
          mixpanel.track(trackedEvents.ArticleSidebarClicked, {
            params:
              itemType === 'blog'
                ? 'whats_popular_blog'
                : 'whats_popuar_community',
            slug,
          });
        }}
      >
        <div className="card relative pt-6">
          <span
            className={clsx(
              'absolute -top-2.5 left-4 rounded-lg p-2 pt-1 pb-1 text-white text-xs font-bold uppercase',
              itemType === 'blog' ? 'bg-blue-500' : 'bg-green-500',
            )}
          >
            {itemType === 'blog' ? 'BLOG' : 'COMMUNITY'}
          </span>
          <div className="flex gap-3">
            <img
              className="h-[52px] w-[100px] rounded-lg"
              src={imageUrl}
              alt={title}
            />
            <div className="flex flex-col gap-1 w-[210px]">
              {itemType === 'blog' ? (
                <>
                  <SectionTitle title={title} />
                  <SectionPublisher author={author} />
                </>
              ) : (
                <>
                  <SectionPublisher author={author} />
                  <SectionTitle title={title} />
                </>
              )}
              <SectionDate
                publishDate={publishDate}
                itemType={itemType}
                answersCount={answersCount}
              />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default PopularArticleItem;
