import { useReducer, useEffect, useState } from 'react';
import { useIsSignedIn } from 'entity/user/userHooks';
import api from 'entity/notification/notificationApi';
import { UserPreferenceData } from 'entity/notification/notificationTypes';

export const PREFERENCE_ACTIONS = {
  TOGGLE_ALL_CHECKED: 'toggle_all_checked',
  TOGGLE_ALL_UNCHECKED: 'toggle_all_unchecked',
  TOGGLE_SWITCH: 'toggle_switch',
  INIT: 'init',
};

const initialPreference = {
  email: false,
  weekly_newsletter: false,
  get_answering: false,
  get_commenting: false,
  web: false,
  new_activity: false,
  suggested_groups: false,
  trending_post: false,
  reward_emails: false,
  reward_web_notifs: false,
};

function init(initialState) {
  return initialState;
}

function reducer(preference, action) {
  switch (action.type) {
    case PREFERENCE_ACTIONS.TOGGLE_ALL_CHECKED:
      if (action.name === 'email') {
        return {
          ...preference,
          email: true,
          weekly_newsletter: true,
          get_answering: true,
          get_commenting: true,
          reward_emails: true,
        };
      }
      if (action.name === 'web') {
        return {
          ...preference,
          web: true,
          new_activity: true,
          suggested_groups: true,
          trending_post: true,
          reward_web_notifs: true,
        };
      }
      return { ...preference };
    case PREFERENCE_ACTIONS.TOGGLE_ALL_UNCHECKED:
      if (action.name === 'email') {
        return {
          ...preference,
          email: false,
          weekly_newsletter: false,
          get_answering: false,
          get_commenting: false,
          reward_emails: false,
        };
      }
      if (action.name === 'web') {
        return {
          ...preference,
          web: false,
          new_activity: false,
          suggested_groups: false,
          trending_post: false,
          reward_web_notifs: false,
        };
      }
      return { ...preference };
    case PREFERENCE_ACTIONS.TOGGLE_SWITCH:
      return { ...preference, [action.name]: !preference[action.name] };
    case PREFERENCE_ACTIONS.INIT:
      return init(action.payload);
    default:
      return { ...preference };
  }
}

function formatSetting(settings: UserPreferenceData) {
  const isEmailActive =
    settings.weekly_newsletter ||
    settings.get_answering ||
    settings.get_commenting ||
    settings.reward_emails;

  const isWebActive =
    settings.new_activity ||
    settings.suggested_groups ||
    settings.trending_post ||
    settings.reward_web_notifs;

  return {
    ...settings,
    email: isEmailActive,
    web: isWebActive,
  };
}

function useSettingsToggle() {
  const [preference, dispatch] = useReducer(reducer, initialPreference, init);
  const [isLoading, setLoading] = useState(false);
  const isSignedIn = useIsSignedIn();

  useEffect(() => {
    async function fetchUserPreferences() {
      const response = await api.getUserPreferences();
      const settings = response.data.data;

      const formatPayload = formatSetting(settings);

      dispatch({ type: 'init', payload: formatPayload });
      setLoading(false);
    }

    if (isSignedIn) {
      setLoading(true);
      fetchUserPreferences();
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (
      !preference.weekly_newsletter &&
      !preference.get_answering &&
      !preference.get_commenting &&
      !preference.reward_email &&
      preference.email
    ) {
      dispatch({
        type: PREFERENCE_ACTIONS.TOGGLE_ALL_UNCHECKED,
        name: 'email',
      });
    }

    if (
      !preference.new_activity &&
      !preference.suggested_groups &&
      !preference.trending_post &&
      !preference.reward_web_notifs &&
      preference.web
    ) {
      dispatch({
        type: PREFERENCE_ACTIONS.TOGGLE_ALL_UNCHECKED,
        name: 'web',
      });
    }
  }, [preference, dispatch]);

  return {
    preference,
    dispatch,
    isLoading,
  };
}

export default useSettingsToggle;
