import React, { useEffect } from 'react';

import useIsMobile from 'utils/hooks/useIsMobile';
import useGetCommunityArticles from 'entity/blog/hooks/useGetCommunityArticles';
import api from 'entity/blog/blogApi';
import PopularArticleItem from './PopularArticleItem';
import PopularArticleLoader from './PopularArticleLoader';
import MobilePopularArticleItem from './MobilePopularArticleItem';
import useTrendingCommunityPost from './hooks/useTrendingCommunityPost';

const HEADER = "What's Popular";

const PopularSection = () => {
  const isMobile = useIsMobile();
  const trendingCommunityPost = useTrendingCommunityPost();
  const {
    articles,
    loadingArticles,
    handleLoadArticles,
  } = useGetCommunityArticles();

  useEffect(() => {
    handleLoadArticles(api.getPopularArticle);
  }, []);

  if (articles.length === 0 && loadingArticles) return null;

  return isMobile ? (
    <>
      <h3 className="text-2xl font-bold p-4 pb-0 bg-white mb-0">
        <span role="img" aria-label="fire">
          🔥
        </span>{' '}
        {HEADER}
      </h3>
      <div className="bg-white p-4 overflow-x-auto flex-wrap">
        <div className="h-[280px] overflow-x-auto overflow-y-hidden flex gap-4 items-center">
          {articles.slice(0, 3).map(item => (
            <MobilePopularArticleItem
              key={item.slug}
              {...item}
              answersCount={item.commentsCount}
              itemType="blog"
            />
          ))}
          {trendingCommunityPost.slice(0, 3).map(item => (
            <MobilePopularArticleItem
              key={item.slug}
              {...item}
              itemType="community"
            />
          ))}
        </div>
      </div>
    </>
  ) : (
    <div className="w-full">
      <h3 className="text-2xl font-bold mb-4">
        <span role="img" aria-label="fire">
          🔥
        </span>{' '}
        {HEADER}
      </h3>
      <ul className="pt-4 space-y-6">
        {articles.length === 0 ? (
          <PopularArticleLoader itemCount={6} />
        ) : (
          <>
            {articles.slice(0, 3).map(item => (
              <li key={item.slug}>
                <PopularArticleItem
                  {...item}
                  answersCount={item.commentsCount}
                  itemType="blog"
                />
              </li>
            ))}
            {trendingCommunityPost.slice(0, 3).map(item => (
              <li key={item.slug}>
                <PopularArticleItem {...item} itemType="community" />
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default PopularSection;
