import React from 'react';
import { useAmp } from 'next/amp';
import PopularSection from 'components/blog/PopularSection';
import SubscriptionForm from 'components/blog/SubscriptionForm';
import PinnedArticleSection from 'components/blog/PinnedArticleSection';
import JoinCommunity from '../JoinCommunity';

const SidebarSection = () => {
  const isAmp = useAmp();
  if (isAmp) return null;
  return (
    <div className="flex flex-col gap-4 items-center">
      <PinnedArticleSection />
      <div className="horizontal-divider w-[85%]" />
      <PopularSection />
      <div className="horizontal-divider w-[85%]" />
      <JoinCommunity />
      <div className="horizontal-divider w-[85%]" />
      <SubscriptionForm />
    </div>
  );
};

export default React.memo(SidebarSection);
