import React from 'react';
import { ArticleWithLink } from 'app/entity/blog/blogTypes';

interface IArticlePageContext {
  article: ArticleWithLink;
}

export const ArticlePageContext = React.createContext<IArticlePageContext>({
  article: null,
});
