import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@component-lib';
import { openLoginModal } from 'dispatcher/modalDispatcher';
import api from 'entity/notification/notificationApi';
import useSettingsToggle from 'pageContents/SettingsContent/NotificationsContent/hooks/useSettingsToggle';
import { RootState } from 'app/reducer';
import Button from 'app/seedly-component-library/button-new';

const TELEGRAM_URL = 'https://bit.ly/seedly-telegram';

export const SubscriptionForm = () => {
  const { preference, isLoading } = useSettingsToggle();
  const [isSubscribed, setSubscribed] = useState(preference.weekly_newsletter);
  const isSignedIn = useSelector(
    (state: RootState) => state.auth.session.isSignedIn,
  );
  const dispatch = useDispatch();

  const buttonText =
    isSignedIn && isSubscribed ? 'You are subscribed!' : 'Subscribe';

  const handleSubscribeClick = async () => {
    if (isSignedIn) {
      if (!isSubscribed) {
        await api.updateNotificationSetting({
          preferences: { weekly_newsletter: true },
        });
        setSubscribed(true);
      }
    } else {
      dispatch(openLoginModal());
    }
  };

  return (
    <div className="h-fit p-4 w-full flex flex-col items-center gap-4 rounded bg-white">
      <p>Stay updated with the latest finance tips!</p>
      {isLoading ? (
        <Spinner />
      ) : (
        <Button onClick={handleSubscribeClick} variant="outline" isBlock>
          {buttonText}
        </Button>
      )}

      <div className="horizontal-divider" />
      <div className="text-blue-500 text-center ">
        Receive bite-sized finance on Telegram{' '}
        <a
          className="font-bold inline-block"
          style={{ textDecoration: 'underline' }}
          href={TELEGRAM_URL}
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </div>
    </div>
  );
};

export default SubscriptionForm;
