import React from 'react';
import Link from 'app/components/misc/Link';
import { pinnedArticles } from 'app/constants/blog';
import mixpanel, { trackedEvents } from 'app/utils/mixpanel';
import noop from 'lodash/noop';

const PinnedArticleList = ({ onItemClick = noop }) => {
  return (
    <div className="flex flex-col gap-2">
      {pinnedArticles.map(article => {
        const formattedViewCount =
          article.views > 1000 ? Math.round(article.views / 1000) : 1;
        return (
          <div
            className="w-full h-fit rounded"
            style={{ backgroundColor: article.backgroundColor }}
            key={article.slug}
          >
            <Link
              passHref
              as={`/${article.slug}`}
              href={`/blog/article?articleSlug=${article.slug}`}
            >
              <a
                className="w-full"
                onClick={() => {
                  onItemClick();
                  mixpanel.track(trackedEvents.ArticleSidebarClicked, {
                    param: 'ultimate_guides',
                    slug: article.slug,
                  });
                }}
              >
                <div className="flex items-center gap-2 px-2 py-3">
                  <img alt="icon" className="h-9 w-10" src={article.image} />
                  <div className="w-full">
                    <p className="uppercase text-sm font-bold text-white">
                      {article.title}
                    </p>
                    {article.views > 0 && (
                      <p className="text-xs text-white">{`${formattedViewCount}k views`}</p>
                    )}
                  </div>
                </div>
              </a>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default PinnedArticleList;
