import { useState, useCallback } from 'react';
import {
  ArticleCommunityResponse,
  ArticleWithLink,
} from 'entity/blog/blogTypes';
import { addLinksToArticle } from 'entity/blog/blogUtils';
import useInsertArticleComment from './useInsertArticleComment';

type ArticleParams = {
  page?: number;
  perPage?: number;
  slug?: string;
};

type ArticleApi = (params: ArticleParams) => Promise<ArticleCommunityResponse>;

// Export out functions to fetch articles
const useGetCommunityArticles = () => {
  const [articles, setArticles] = useState<ArticleWithLink[]>([]);
  const [articlePage, setArticlePage] = useState(1);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingMoreArticles, setLoadingMoreArticles] = useState(false);
  const [haveMorePage, setHaveMorePage] = useState(false);
  const handleFeatureComment = useInsertArticleComment();

  const handleLoadMoreArticles = useCallback(
    async (articleApi: ArticleApi, params: ArticleParams = {}) => {
      setLoadingMoreArticles(true);
      const blogResponse = await articleApi({
        perPage: 10,
        page: articlePage + 1,
        ...params,
      });
      const articlesNextPage = blogResponse.ok
        ? blogResponse.data.data.map(addLinksToArticle)
        : [];
      setLoadingMoreArticles(false);
      setArticlePage(blogResponse.ok ? articlePage + 1 : articlePage);
      setArticles([...articles, ...articlesNextPage]);
      const pagination = blogResponse.data?.meta?.pagination;
      setHaveMorePage(
        pagination ? pagination.totalPage > pagination.currentPage : false,
      );
      handleFeatureComment([...articles, ...articlesNextPage]);
    },
    [articlePage, articles, handleFeatureComment],
  );

  const handleLoadArticles = useCallback(
    async (articleApi: ArticleApi, params: ArticleParams = {}) => {
      setLoadingArticles(true);
      const blogResponse = await articleApi({
        page: 1,
        perPage: 10,
        ...params,
      });
      const fetchedArticles = blogResponse.ok
        ? blogResponse.data.data.map(addLinksToArticle)
        : [];
      setLoadingArticles(false);
      setArticlePage(1);
      setArticles([...fetchedArticles]);
      const pagination = blogResponse.data?.meta?.pagination;
      setHaveMorePage(
        pagination ? pagination.totalPage > pagination.currentPage : false,
      );
      handleFeatureComment([...fetchedArticles]);
    },
    [handleFeatureComment],
  );

  return {
    articles,
    haveMorePage,
    currentPage: articlePage,
    loadingArticles,
    loadingMoreArticles,
    handleLoadMoreArticles,
    handleLoadArticles,
  };
};

export default useGetCommunityArticles;
