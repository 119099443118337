import React from 'react';
import PinnedArticleList from 'app/components/blog/PinnedArticleList';

const PinnedArticleSection = () => {
  return (
    <div className="p-4 bg-white rounded gap-6 flex flex-col">
      <p className="text-xl font-bold text-neutral-500">
        Ultimate guides to <span className="text-blue-500">kickstart</span> your
        finances{' '}
      </p>
      <PinnedArticleList />
    </div>
  );
};

export default PinnedArticleSection;
